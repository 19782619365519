<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-7">
                <p id="idExsampel" class="text-muted" >
                  Kartu Peserta Ujian Mahasiswa
                </p>
              </div>
              <div class="col-md-5">
                <select class="selectTahun form-control form-control-rounded50" data-placeholder="Piliih Tahun Akademik" data-allow-clear="1"
                  v-model="dataSMT.smt_id">
                  <option v-for="smt in tahunAkademik" v-bind:value="smt.smt_id" :key="smt.smt_id">
                    {{ smt.smt_nama }}
                  </option>
                </select>
              </div>
            </div>
            
          </div>
          <div class="card-body padding-t-0">
            <div class="widget white-bg  mb-10">
              <div class="row">
                <div class="col-md-3 col-xs-6 b-r"> <strong>{{ profil.nama_lengkap }}</strong>
                  <br>
                  <p class="text-muted">{{ profil.username }}</p>
                </div>
                <div class="col-md-4 col-xs-6 b-r"> <strong>{{ profil.nama_prodi }}</strong>
                  <br>
                  <p class="text-muted">T.A {{ dataSMT.smt_nm }}</p>
                </div>
                <div class="col-md-2 col-xs-6 b-r"> <strong>Jumlah S K S </strong>
                  <br>
                  <p class="text-muted">{{ jumlahSKS }}</p>
                </div>
                <div class="col-md-3 col-xs-6 b-r"> 
                    
                </div>
              </div>
            </div>
            <template v-if="!isLoading">
              <div v-show="isError && !isLoading" class="alert bg-danger alert-dismissible" role="alert" >
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" >
                  <span aria-hidden="true">×</span>
                </button>
                {{ isMessage }}
              </div>
               <div v-show="!isError && !isLoading" class="mb-10">
                  <button type="button" v-on:click="downloadKPU"  class="btn btn-warning btn-rounded btn-sm btn-icon"><i class="fa fa-cloud-download"></i> Download &nbsp;</button> &nbsp;
                  <button type="button" v-on:click="printKPU"  class="btn btn-primary btn-rounded btn-sm btn-icon"><i class="fa fa-print"></i> Cetak &nbsp;</button>
               </div>
            </template>
              <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>KODE</th>
                  <th>NAMA MATAKULIAH</th>
                  <th>S K S</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(krs, num) in dataKRS" :key="krs.id_nil">
                  <td>{{ num + 1 }}</td>
                  <td>{{ krs.kd_mak }}</td>
                  <td>{{ krs.nm_mak }}</td>
                  <td>{{ krs.mk_sks }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import printJS from 'print-js'
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getSMT } from '@/services/mahasiswa.service'
import { getKHS, cetKPU } from '@/services/khs.service'
import { ref } from 'vue'
export default {
  name: 'RiwayatKhs',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      dataKRS: [],
      tahunAkademik: [],
      khsVal: [],
      dataSMT: {},
      profil: users,
      params: { smt_id: '' },
      nilaiIPS: 0,
      loadData: false,
      loadMhs: ref(false),
      loadThnAkademik: false,
      loadSubmit: false,
      fullPage: true,
      onClikTahun: false,
      isError: ref(),
      isMessage: ref(''),
      errorData: {}
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadMhs || this.loadThnAkademik || this.loadSubmit))
      return load
    },
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return (totKredit) || 0
    }
  },
  created () {
    this.getTahunAkademik()
    this.handleKPU()
  },
  mounted () {
    var vm = this
    $('.selectTahun').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.params.smt_id = e.params.data.id
      vm.onClikTahun = true
      vm.handleKPU()
    }).trigger('change')
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    async getTahunAkademik () {
      this.loadThnAkademik = true
      getSMT({}).then(response => {
        if (response.error_code === 200) {
          this.tahunAkademik = response.records
        }
        this.loadThnAkademik = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadThnAkademik = false
      })
    },
    async handleKPU () {
      this.loadData = true
      if (this.onClikTahun) { this.loadData = true }
      this.isError = false
      const params = {
        id_reg_pd: this.profil.id_reg_pd,
        th_smtmasuk: this.profil.id_smt_masuk,
        id_smt: this.params.smt_id
      }
      getKHS(params).then(response => {
        this.dataSMT = response.tahun_akademik
        if (response.error_code === 200) {
          this.isError = false
          this.dataKRS = response.records
          this.statusKRS = response.status
          this.nilaiIPS = response.ips
        } else {
          this.dataKRS = [{}]
          this.isError = true
          this.isMessage = response.messages
        }
        if (this.onClikTahun) { this.loadData = false }
        this.loadData = false
      }).catch(error => {
        this.loadData = false
        this.errorData = (error.response) ? {} : {}
        if (this.onClikTahun) { this.loadData = false }
      })
    },
    async printKPU () {
      this.loadSubmit = true
      cetKPU({ reg: this.profil.id_reg_pd, smt: this.params.smt_id }).then((response) => {
        printJS(window.URL.createObjectURL(new Blob([response])))
        this.loadSubmit = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadSubmit = false
      })
    },
    async downloadKPU () {
      this.loadSubmit = true
      cetKPU({ reg: this.profil.id_reg_pd, smt: this.params.smt_id }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'KPU ' + this.profil.username + '.pdf')
        document.body.appendChild(fileLink)

        fileLink.click()
        this.loadSubmit = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadSubmit = false
      })
    }
  }
}
</script>
